<template>
    <div class="p-1 px-3">
            <b-alert :show="showError" variant="danger" class="p-1 w-100">
                <b>Error: </b> Render failed because current file is corrupted. Please upload a new file and try again!
           </b-alert>
           <div class="w-100 my-1 d-lg-none">
                <app-simple-button :disabled="!loaded || collaborators.length<1" variant="primary" class="w-100" @click="sendDocument()" :loading="sendLoading">Send Document</app-simple-button>
            </div>
           <app-simple-button   variant="primary" class="w-100 mb-1 d-lg-none" @click="()=>show_sidebar=true" >Add Collaborators</app-simple-button>
           <b-sidebar class="d-lg-none "  v-model="show_sidebar" backdrop backdrop-variant="light" width="531px" bg-variant="white" right no-header-close>
                <div class="p-2 border-bottom mt-5">
                    
                    <app-heading-2 class="mt-1"><feather-icon icon="ChevronLeftIcon" size="22" @click="()=>show_sidebar=false" ></feather-icon>{{$route.query.requireSignatures==1?'Signing Options':'Collaborators'}}</app-heading-2>
                </div>
                <add-collaborators ref="addCollaborators" :page="currentPage" @onCollaboratorAdded="addSignature" @onRemove="removeCollaborator"/>
                <div class="px-2 button-bottom w-100">
                    <app-simple-button :disabled="!loaded || collaborators.length<1" variant="primary" class="w-100" @click="sendDocument()" :loading="sendLoading">Send Document</app-simple-button>
                </div>
           </b-sidebar>
        <div class="row main-card">
            <div class="col-lg-9  col-12">
                <document-viewer-2 ref="docViewer" :default-signature-boxes="signaturePlacements" @currentPage="setCurrentPage" @renderFailed="()=>showError=true"  v-if="document" :initialDoc="document" />
            </div>
                <div class="col-3 side-card d-lg-block d-none">
                    <div class="p-2 border-bottom">
                        <app-heading-2>{{$route.query.requireSignatures==1?'Signing Options':'Collaborators'}}</app-heading-2>
                    </div>
                    <add-collaborators ref="addCollaborators" :page="currentPage" @onCollaboratorAdded="addSignature" @onRemove="removeCollaborator"/>
                    <div class="px-2 button-bottom w-100">
                        <app-simple-button :disabled="!loaded || collaborators.length<1" variant="primary" class="w-100" @click="sendDocument()" :loading="sendLoading">Send Document</app-simple-button>
                    </div>
                </div>
        </div>

    </div>
</template>
<script>
import AppHeading2 from "@/components/app-components/AppHeading2.vue"
import AppHeading3 from "@/components/app-components/AppHeading3.vue"
import AppSimpleButton from "@/components/app-components/AppSimpleButton.vue"
import DocumentViewer2 from "@/components/functional-components/other/documents/DocumentViewer2.vue"
import AddCollaborators from "@/components/functional-components/other/documents/AddCollaburators.vue"
import AppTextInput from "@/components/app-components/AppTextInput.vue"
import {get_document, send_document} from "@/apis/others/documents"
import { BButton, BAlert, BSidebar,BOverlay,BSpinner } from "bootstrap-vue"
export default{
    components: {
        AppHeading2,
        AppHeading3,
        AppSimpleButton,
        BButton,
        BAlert,
        BSidebar,
        AppTextInput,
        DocumentViewer2,
        AddCollaborators,
        BOverlay,
        BSpinner
    },
    data(){
        return{
            collaborator_details:{
                name:'',
                email:'',
            },
            collaborators:[],
            show:true,
            document:'',
            signaturePlacements:[],
            currentPage:1,
            sendLoading:false,
            loaded:false,
            showError:false,
            show_sidebar:false,
            requireSignature:false,
            loading:false,
        }
    },
    mounted(){
        this.getDocument(this.$route.params.id)
    },
    methods:{
        getDocument(id){
            this.loading=true;
            get_document(id).then(resp=>{
                this.loading=false;
                this.document = resp.data.fileLink
                if(resp.data.document.signaturesRequired){
                    this.signaturePlacements = resp.data.document.signaturePlacements
                }
                this.collaborators=resp.data.document.signaturePlacements
                this.requireSignature=resp.data.document.signaturesRequired
                this.$refs.addCollaborators.projectId=resp.data.document.folder.parentFolder?resp.data.document.folder.parentFolder:resp.data.document.folder._id
                resp.data.document.collaborators.map(c=>{
                    let obj={
                        name:`${c.first_name} ${c.last_name}`,
                        email:c.email,
                        id:c._id
                    }
                    this.$refs.addCollaborators.addCollaboratorToList(obj)
                })
            })
        },
        addMore(){
            this.show=true
        },
        addSignature(details){
            this.collaborators.push(details)
            if(this.requireSignature){
                this.$refs.docViewer.addSignatureBox(details)
            }
        },
        sendDocument(){ 
            this.sendLoading=true
            send_document(this.$route.params.id).then(resp=>{
                this.sendLoading=false
                this.$bvToast.toast(resp.message, {
                    title: 'Document Sent',
                    variant: 'success',
                    solid: true,
                })
                setTimeout(() => {
                    this.$router.push('/documents')
                },[1000]);
            }).catch(err=>{
                this.sendLoading=false
                this.$bvToast.toast(err.message, {
                    title: 'Failed to send document',
                    variant: 'danger',
                    solid: true,
                })
            })
        },
        removeCollaborator(c){
            let index=this.collaborators.findIndex((cc)=>cc.id==c.id)
            this.collaborators.splice(index,1)
            if(this.requireSignature){
                this.$refs.docViewer.removeSignatureBox(c);
            }
        },
        setCurrentPage(p){
            this.loaded=true;
            this.currentPage=p
        }
    }
}
</script>
<style scoped>
.main-card{
    border-radius: 8px;
    height: 100%;
    border: 1px solid #EAECF0;
}
.collaborator-card{
    padding: 12px;
    border-radius: 8px;
    background-color: #e6fcff;
}
.email-avatar{
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
    height: 36px;
    width:36px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 18px;
    font-family: 400;
    color: #000000;
    background-color: #a1f3ff;
}
.signature-card{
    border-radius: 6px;
    background-color: #f8fafc;
}
.small-heading{
    line-height: 16px;
    font-weight: 400;
    size:13px;
    color: #000000;

}
.text-small{
    font-size:14px;
    font-weight: 400;
    line-height: 16px;
    color:#4B465C;
}
.side-card{
    background-color: #ffffff;
    border-radius: 0px 8px 8px 0px;
    position: relative;
}
.button-bottom{
    position: absolute;
    bottom: 20px;
}
@media(max-width:768px){
    .b-sidebar-outer{
        z-index: 10 !important;
    }
}
</style>